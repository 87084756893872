// NO JQUERY
var productDetails = function () {
	var glide = new Glide4imprint(),
	forms = new forms4i(),
	currencySymbol = "$",
	txtTimer = null,
	textUpdateSliderFinish = null,
	itemSoldOut = document.getElementById("soldOutItem").value === "True", // product/153961-OL/12-Pack-Cooler
	isGlide = document.getElementsByClassName("glide")[0] ? true : false,
	arrBreak = document.getElementById("arrBreak") ? document.getElementById("arrBreak").value.split(",").map(Number) : "",
      	arrPrice = document.getElementById("arrPrice") ? document.getElementById("arrPrice").value.split(",").map(Number) : "",
      	steppingQty = document.getElementById("steppingQty") ? document.getElementById("steppingQty").value : "",
      	maxQuantityErrorHtml = '<div class=\"alertBoxFlex displayFlex flexNoWrap\" id="glideMaxAlert"><svg class="alertBulb" height="25px" width="25px" aria-hidden="true"><use xlink: href="#svgIcon-hint"></use></svg><div class="alertText">We\'re sorry, the maximum quantity is ##MAXQTY## for this item. The amount you entered has been adjusted.</div></div>',
      	tinySlider; 
	 
	var initializeScroller = function(sid){
		var container = document.getElementById(sid),
		slider = container.querySelector('.Scroller');
		if(slider){
			tinySlider = new tns({
				container: slider,
				mouseDrag: true,
				lazyload: true,
				prevButton: container.querySelector('.slider-prev'),
				nextButton: container.querySelector('.slider-next'),
				items: 1,
				gutter: 20,
				slideBy: "page",
				nav:false,
				navPosition:"bottom",
				loop: false,
				responsive: {
					1024:{
						items: 5,
						nav:true
					},
					800: {
						items: 4,
						nav:true
					},
					480: {
						items: 3
					}
				}
			});
		}
	},  	
	initFocus = function(){
		var options;
		var observer = new IntersectionObserver(callBack, options);
		/* once element is in view - do this */
		function callBack(entries, observer) {
			entries.forEach(function (entry) {
				if (entry.intersectionRatio > 0) {
					if (entry.target === document.getElementById('stealADealNotify')) {
						ajaxActions.GetSADNotifyForm();								
						observer.unobserve(document.getElementById('stealADealNotify'));
					}
					if (entry.target === document.getElementById('similarScroller')) {
						ajaxActions.GetSimilarItemScroller();
						observer.unobserve(document.getElementById('similarScroller'));
					}
				}
			});
		}
		/* steal a deal notifier form */
		if (document.getElementById('stealADealNotify')) {
			options = { root: document.getElementById('stealADealNotify'), threshold: 1.0 };
			observer.observe(document.getElementById('stealADealNotify'));
		}

		/*similar items product scroller*/
		if (document.getElementById('similarScroller')) {
			options = { root: document.getElementById('similarScroller'), threshold: 1.0 };
			observer.observe(document.getElementById('similarScroller'));
		}		
	},	
	sliderGlide = (function(){
    		var glidePrice,
    		glideTotal,
    		glideQuantity,
    		glideAmountSaved,
    		glideDiscountDiv,
    		glideTotalDiv,
    		glideQtyDiv,
    		glideToolTip,
    		glideWrapper,
    		glideSlider,
    		step2Btn,
    		glideMinPoint,
    		glideMaxPoint,
    		singleBreak,
    		originalPrice,
    		notEnoughError,
    		lowestQty,
    		priceBusy,
  
  		initVariables = function(){
      			glidePrice = document.getElementById('lblPrice'), 
      			glideTotal = document.getElementById("lblTotal"), 
      			glideQuantity = document.getElementById("txtQty"), 
      			glideAmountSaved = document.getElementById("YouSaveBox"), 
      			glideDiscountDiv = document.getElementById("divYouSave"),
        		glideTotalDiv = document.getElementById("litGlideTotal"), 
        		glideQtyDiv = document.getElementById("lblQuantityTxtCaption"), 
        		glideToolTip = document.querySelector(".glideToolTip"), 
        		glideWrapper = document.querySelector(".glide"), 
        		glideSlider = document.querySelector(".glideSlider"),
      			step2Btn = document.querySelector(".step1"),
      			notEnoughError = document.querySelector('.notenoughavailable'),
      			glideMinPoint = glide.MinPoint(),
      			glideMaxPoint = glide.MaxPoint(),
      			originalPrice = parseInt(document.getElementById("originalPrice1").value),
      			singleBreak = arrBreak[2] <= 0;
      			lowestQty = document.getElementById("minVal").value;
      			priceBusy = '<div class="spinner" aria-live="polite" aria-atomic="true" aria-label="Loading new price"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div>';
    		},
    		init = function(){
      			if(isGlide){
        			initVariables();
        			
      				glideSlider.setAttribute("min", glideMinPoint);
      				glideSlider.setAttribute("max", glideMaxPoint);

			      /* IE11 does not support input event on ranges - boo 
			      ** aka we'd have to use just the on change event but this
			      ** will not update the tooltip qty and price as you slide, only when you finish sliding
			      */
			      // https://www.impressivewebs.com/onchange-vs-oninput-for-range-sliders/
			      // internet explorer
      				glideSlider.addEventListener("change", function(e){
        				e.preventDefault();
        				updatePrice(glideSlider.value.toString());
        				document.getElementById("ItemQuantity").innerHTML = document.getElementById("txtQty").value;
      				});
      
      				// firefox and chrome
      				glideSlider.addEventListener("input", function(e){
        				e.preventDefault();
        				updatePrice(glideSlider.value.toString());
        				document.getElementById("ItemQuantity").innerHTML = document.getElementById("txtQty").value;
      				});
      
      				window.addEventListener("resize", function(){
        				if(window.innerWidth >= 800){
          					updateToolTip(); //recalculate the tooltip position 
        				}
      				});

            			glideQuantity.addEventListener('keyup', function (e) {
                			if ((e.which && e.which == 13) || (e.keyCode && e.keyCode == 13)) { //enter key
                    				textUpdateSliderFinish(glideQuantity.value, true);
                			} else {
                    				textUpdateSlider(glideQuantity.value, true);
                			}
                			return false;
            			});
            
            			// when using up/down arrows on right side of input
            			glideQuantity.addEventListener('change', function () { 
              			textUpdateSliderFinish(glideQuantity.value, true);
            			});
            
            			// set glides initial value
            			var startPoint = Math.round((parseInt(document.getElementById("maxVal").value) - document.getElementById("minVal").value) * 0.3) + parseInt(document.getElementById("minVal").value);
            			glideSlider.value = glide.SetSlideQty(startPoint);
                      	triggerChange();
            			
            			// If editing, update the slider with the appropriate qty
            			if (document.getElementById("editQty").value > 0) {
                			textUpdateSliderFinish(document.getElementById("editQty").value, document.getElementById("updateIndex").value);
            			}
            			
            			// updates the tooltip after everything on page has loaded and is in correct position
            			setTimeout(function () { 
              			updateToolTip();
              			glideToolTip.classList.remove("invisible"); //show tooltip after set correctly - prevents the visual jump
          			}, 600);
          		}
    		},
    		updateToolTip = function(){
      			// position
      			var val = parseInt(glideSlider.value, 10);
      			var pos = (val - glideMinPoint)/(glideMaxPoint - glideMinPoint);
    			var glideThumbWidth = 34;
    			var thumbCorrect = glideThumbWidth * (pos - 0.5) * -1;
    			var titlepos = Math.round(( pos * glideSlider.offsetWidth ) - glideThumbWidth/4 + thumbCorrect );
    			glideToolTip.style.left = (titlepos + 20) + "px";

    			// content
    			var qty = glideSlider.value;
    			var firstDigit = glide.GetBreakSegment(qty);
          		var quantity = glide.GetSlideQty(firstDigit, qty);
    			glideToolTip.textContent = quantity;
    		},
    		textUpdateSlider = function(qty, focus) {
    			glideTotal.innerHTML = priceBusy;
    			
          		if (txtTimer != null) {
            			clearTimeout(txtTimer);
          		}
          		var updateTime = 3000; // give more time before correcting to lowest qty amount
          		if (qty > lowestQty) updateTime = 1500;
          			txtTimer = setTimeout(function () {
            			textUpdateSliderFinish(qty, focus);
          		}, updateTime);
      		},
      		updatePrice = function(qty) {  		
          		var firstDigit = glide.GetBreakSegment(qty);
          		var quantity = glide.GetSlideQty(firstDigit, qty);

          		if (parseInt(lowestQty) <= quantity) {
            			if (firstDigit != null) {
                			var newPrice = glide.GetPriceEstimate(firstDigit, quantity, qty);
                			var startPrice = originalPrice; // pricing always starts at index 1
                			//var startPrice = arrPrice[1]; // pricing always starts at index 1
              
                			updateToolTip();
                
                			glidePrice.value = currencySymbol + newPrice.toFixed(2);
                			glideTotal.innerHTML = currencySymbol + (newPrice * quantity).toFixed(2);
                			glideQuantity.value = quantity;
                			
                			if (Math.round(newPrice * 100) >= Math.round(startPrice * 100)) {
                    				if (!glideAmountSaved.classList.contains("invisible")) {
                      				glideAmountSaved.classList.add("invisible");
                    				}
                			} else {
                    				if (glideAmountSaved.classList.contains("invisible")) {
                      				glideAmountSaved.classList.remove("invisible");
                    				}
                    				glideDiscountDiv.innerHTML = (currencySymbol + ((startPrice * quantity) - (newPrice * quantity)).toFixed(2));
                			}
            			}
          		}
	          	// make sure warnings aren't displaying
    			notEnoughError.innerHTML = '';
      		},
      		triggerChange = function(){
      			var event = document.createEvent("Event");
      			event.initEvent("change", true, true);
      			glideSlider.dispatchEvent(event);
      		},
      		textUpdateSliderFinish = function(quantity, focus) {	
        		quantity = Math.round(quantity); // if decimals are used in the input box
          		if(quantity == "" || quantity < 0){ //enforce min quantity
            			glideQuantity.value = lowestQty;
            			glideSlider.value = glide.SetSlideQty(lowestQty);
              		sliderGlide.TriggerChange();
          		}
          		if (glide.IsNumeric(quantity)) {
            			var qty = parseInt(quantity);
            			var SlideQty = qty;
            			var quantBoxValue = glideQuantity.value;
            			var price;
            			if (lowestQty > qty) {
            				qty = lowestQty;  //enforce min quantity   		
            			}
            			     			
 				if (qty > maxQty) {
					const maxBreak = glide.MaxBreakQty();
					qty = maxBreak;
					glideQuantity.value = maxBreak;
					quantBoxValue = maxBreak;
					document.querySelector('.glideWrapper').insertAdjacentHTML('beforebegin', maxQuantityErrorHtml.replace('##MAXQTY##', glide.MaxBreakQty()));
				}           			
            			         			  			
            			// Only update the slider if there are multiple qty breaks
            			if (singleBreak) { 
              			price = glidePrice.value.trim().substring(1); // Trims the currency symbol
              			glideTotal.innerHTML = (currencySymbol + (price * qty).toFixed(2));
            			}else{
              			var lastBreakQty = glide.MaxBreakQty();
              			// glideMaxPoint contains the maximum slide val and not quantity, cannot accurately compare the two - requires a conversion
              			if (qty > lastBreakQty) { // If the qty is greater than the end of the slider, need to set the value to slide to to the last break
                				glideSlider.value = glide.SetSlideQty(lastBreakQty);
                				triggerChange();
              			}else{
                      			//glideSlider.dispatchEvent(new Event('change')); // does not work in IE 11
                				glideSlider.value = glide.SetSlideQty(quantBoxValue);
                      			triggerChange();
                			}
            			}
            			// if user has selected a specific color
            			if(document.getElementById("panelOne").classList.contains("bkgdGreen")){
	            			var qtyleft;
	            			if(document.querySelector(".selectedProdColor")){
	            				qtyleft = parseInt(document.querySelector(".selectedProdColor").querySelector(".stockAvailable").innerHTML);
	            			}else{
	            				qtyleft = parseInt(document.querySelector(".stockAvailable").innerHTML);
	            			}
	    				if (qty > qtyleft) {
	                			notEnoughError.innerHTML = 'There are only ' + qtyleft + ' of your selected product available.';
	                			qty = qtyleft;
	            			} else {
	                			notEnoughError.innerHTML = '';
	            			}
            			}
            			// The slider doesn't deal in qtys, just percentages, so sometimes it will adjust the qty when it does
            			// its percentage calculation if the user typed a qty. This will reset it to the user entered value.
            			if (qty > SlideQty) {
              			glideQuantity.value = qty;
              			glideToolTip.textContent = qty;
              			price = glidePrice.value.trim().substring(1); // Trims the currency symbol
              			glideTotal.innerHTML = (currencySymbol + (price * qty).toFixed(2));
              			document.getElementById('divYouSave').innerHTML = "$" + ((parseInt(document.getElementById("originalPrice1").value) * qty) - (price * SlideQty)).toFixed(2).toString();
            			} else {
            				if (parseInt(glideQuantity.value) != qty) {
              				const enforcedStepQty = glide.EnforceSteppingQty(qty, steppingQty);
                				if (enforcedStepQty == qty) {
                  					glideQuantity.value = qty;
                  					glideToolTip.textContent = qty;
                  					price = glidePrice.value.trim().substring(1);
                  					glideTotal.innerHTML = (currencySymbol + (price * qty).toFixed(2));
                				} else {
                  					glideSlider.value = glide.SetSlideQty(enforcedStepQty);
                      				triggerChange();
                				}
            				}
            			}
        		}
      		};
    		return{
      			Init: init,
      			UpdateToolTip: updateToolTip,
      			TriggerChange: triggerChange
    		};
  	})(),
  	
  	productColorPanel = (function () {
  		var index;
    		var selectableColors;
		var colorQtyList;
		var OrderCount;
		var intervalIndex = [];

    		var init = function () {
    			index = document.getElementById("updateIndex").value;
			selectableColors = document.getElementById("colorOptions" + index) ? [].slice.call(document.getElementById("colorOptions" + index).querySelectorAll(".colorOption")): [];
			colorQtyList = [].slice.call(document.querySelectorAll('.colorStock'));
			
			selectableColors.forEach(function (el, index, array) {
                		el.addEventListener("click", function (e) {
                			e.preventDefault();
                			selectColor(el);
                		});
                		el.addEventListener("keyup", function (e) {
                			if(e.which == 13){
                				e.preventDefault();
                				selectColor(el);
                			}
                		});
	            	});
			//quantityLeftSummary(document.getElementById('ProductID').value);
            	}, 
            	antiForgeryToken = { "RequestVerificationToken": document.querySelectorAll('input[name=__RequestVerificationToken]')[0].value },
            	verificationToken = document.querySelectorAll('input[name=__RequestVerificationToken]')[0].value,
            	selectColor = function(prodColor){
            		if(document.querySelector(".stealError")){ document.querySelector(".stealError").parentNode.removeChild(document.querySelector(".stealError")); }
			
			clearSelected();
	        	prodColor.classList.add("selectedProdColor");
	        	//prodColor.classList.remove("makeTransparent");
	        	prodColor.setAttribute("aria-pressed", "true");
	        		
            		document.getElementById("selectedColor0").innerHTML = prodColor.querySelector(".colorInfo").textContent;
            		document.getElementById("selectedColorID").value = prodColor.id;

            		if (document.querySelector(".glideSlider")) {
				var qtyleft = parseInt(prodColor.querySelector(".stockAvailable").innerHTML);
                			if (qtyleft < parseInt(document.getElementById("txtQty").value)) {
                    			document.querySelector(".glideSlider").value = glide.SetSlideQty(qtyleft); 
              	    			sliderGlide.TriggerChange();
				}
            		}
            		formEvents.UpdateStepsComplete();
            	},
            	quantityLeftSummary = function(productID) {
            		//Update quantityleft in one ajax call
	    		setInterval(UpdateCount(productID), 3000);
		},		
		UpdateCount = function(productID) {
			var qtyLoadingIcon = '<span class="spinner" role="alert" aria-busy="true" aria-atomic="true" aria-label="Loading quantity, please wait"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></span>'
			if(document.querySelector(".countError")){ 
				document.querySelector(".countError").parentNode.removeChild(document.querySelector(".countError"));
			}
			
			var stockAvailable = [].slice.call(document.querySelectorAll(".stockAvailable"));
			stockAvailable.forEach(function(el){
				el.innerHTML = qtyLoadingIcon;
	 		});
					    	
		    	var data = { productID: productID };
		    		        			
			fetch('/sad/checkinventory', { 
				method: 'POST', 
				body: JSON.stringify(data), 
				headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch', "RequestVerificationToken": verificationToken }
			}).then(function(response){
				return  (response.ok) ? response.json() : []; // response.json() returns a promise
			}).then(function(response){
				if(!response.error){
		    			var minQty = document.getElementById("minVal");
		    			var quantitySelected = document.getElementById('ItemQuantity').innerHTML;
		    			
		    			for(var i = 0; i < response.inventory.length-1; i++){
		    				var stock = response.inventory[i];
		    				var el = document.getElementById("quantityLeft_" + stock.baseID + "_" + stock.trimID);
		    				
		    				if (minQty && (stock.quantity <= minQty.value * 2)) {
		    					if(document.getElementById("stock" + i)){
		    						document.getElementById("stock" + i).classList.add("textDkRed");
		    					}
		                			if(document.getElementById("urgent" + i)){
		                				document.getElementById("urgent" + i).classList.remove("hide");
		                			}
		                			el.innerHTML = stock.quantity;
		            			}else {
		                			el.innerHTML = stock.quantity;
		            			}
		            			if (stock.quantity < quantitySelected) {
		                			var quantityError = '<p class="countError textSemiBold textDkRed text18" role="alert" aria-atomic="true">You cannot buy ' 
		                			+ quantitySelected + ' because there are only ' + stock.quantity + ' left.</p>';
    							document.querySelector(".panelOne").querySelector(".panelMain").insertAdjacentHTML("afterbegin", quantityError);
		            			}
		            		}
		             	}
			}).catch(function(error){
				// place error handling here
			});
		},
            	clearSelected = function () {
                	selectableColors.forEach(function (el, index, array) {
                    		el.classList.remove("selectedProdColor"); 
                    		//el.classList.add("makeTransparent");
                    		el.setAttribute("aria-pressed", "false");
               	});
            	};

            	return {
                	Init: init
            	};
	})(),
	
	imprintColorPanel = (function(){
		var resetImprintColors = function(parent){
			var locationImprintColors = [].slice.call(parent.querySelectorAll(".imprintColor"));
			locationImprintColors.forEach(function(el){
				el.classList.remove("selectedImprintColor");
				//el.classList.add("makeTransparent");
				el.setAttribute("aria-pressed", "false");
	 		});
		},
		selectColor = function (el) {
			var imprintLocationIds = [];
			var productImprintLocationIds = [];
			var imprintColorIds = [];
        		//var imprintColorID = el.querySelector('.imprintColorID').value;
        		//var imprintLocationID = el.querySelector('.imprintLocationID').value;
        		//var productImprintLocationID = 	el.querySelector('.productImprintLocationID').value;
        		var imprintColorID = el.parentNode.querySelector('.imprintColorID').value;
        		var imprintLocationID = el.parentNode.querySelector('.imprintLocationID').value;
        		var productImprintLocationID = 	el.parentNode.querySelector('.productImprintLocationID').value;
        		imprintLocationIds.push(imprintLocationID);
        		productImprintLocationIds.push(productImprintLocationID);
        		imprintColorIds.push(imprintColorID);
        		
        		resetImprintColors(el.parentNode.parentNode);
        		el.classList.add("selectedImprintColor");
        		//el.classList.remove("makeTransparent");
        		el.setAttribute("aria-pressed", "true");
        		
        		//var updateIndex = el.querySelector('.updateIndex').value; 
			//var selectedColor = el.querySelector('.selectedColor').value; 
			//var paletteHex = el.querySelector('.paletteHex').value; 
			//var imprintColorDesc =el.querySelector('.imprintColorDesc').value;  
			//var imprintColorID = el.querySelector('.imprintColorID').value; 
			var updateIndex = el.parentNode.querySelector('.updateIndex').value; 
			var selectedColor = el.parentNode.querySelector('.selectedColor').value; 
			var paletteHex = el.parentNode.querySelector('.paletteHex').value; 
			var imprintColorDesc =el.parentNode.querySelector('.imprintColorDesc').value;  
			var imprintColorID = el.parentNode.querySelector('.imprintColorID').value; 
			
			setImprintColor(updateIndex, selectedColor,  paletteHex, imprintColorDesc, imprintColorID, imprintLocationID, productImprintLocationID);
        		
		},
		setImprintColor = function (updateIndex, colorID, hex, colorDesc, imprintColorID, imprintLocID, prodImprintLocID) {
			var imprintContainer = document.getElementById("imprintContainer".concat(imprintLocID));
			//var selectedImprintColor = imprintContainer.querySelector("#selectedImprintColor".concat(updateIndex));
			var selectedColorText = imprintContainer.querySelector(".selectedColorText");
			
			//selectedImprintColor.style.background = hex;							
			selectedColorText.setAttribute('imprintlocationid', imprintLocID);
			selectedColorText.setAttribute('productimprintlocationid', prodImprintLocID);
			selectedColorText.setAttribute('imprintcolorid', imprintColorID);
 			
 			selectedColorText.innerHTML = colorDesc;
			
			document.getElementById('ImprintColor').innerHTML = "";
 			var selectedColors = [].slice.call(document.querySelectorAll(".selectedColorText"));
 			if(selectedColors.length > 0){
 				selectedColors.forEach(function(el){
 					document.getElementById('ImprintColor').innerHTML += el.textContent + " ";
 				});
 			}else{
 				document.getElementById('ImprintColor').innerHTML = selectedColors[0].textContent;  
 			}
 			
    			document.getElementById('ImprintColorID').value = imprintColorID;  	
    			document.getElementById('ImprintLocationID').value = imprintLocID;
    			document.getElementById('ProductImprintLocationID').value = prodImprintLocID;

    			
    			var quantity = document.getElementsByClassName('QuantityEntryTxtBox');
    			var sadquantity = document.getElementsByClassName('SADQuantity');
    			   			
    			var quantityValue = 0;
    			
    			if(quantity.length > 0){
    				quantityValue = parseInt(quantity[0].value);
    			} else if(sadquantity.length > 0){
    				parseInt(sadquantity[0].innerHTML);
    			}
    			
    			formEvents.UpdateStepsComplete();
		},
		init = function(location){
			var imprintColors = [].slice.call(location.querySelectorAll(".imprintColor"));
		
			if(imprintColors.length > 0){
				imprintColors.forEach(function(el){
					el.addEventListener('click',	function(e){
			         		e.preventDefault();
						selectColor(el);
			 		});
			 		el.addEventListener('keyup', function(e){
			 			if(e.which == 13){
			         			e.preventDefault();
							selectColor(el);
						}
			 		});
		 		});
		 	}else{
		 		//one imprint color
		 		document.getElementById("ImprintColor").textContent = document.querySelector(".selectedColorText").textContent;
		 		document.getElementById('ImprintColorID').value = document.querySelector('.selectedColorText').getAttribute('imprintcolorID');
        			document.getElementById('ImprintLocationID').value = document.querySelector('.selectedColorText').getAttribute('imprintlocationID');
        			document.getElementById('ProductImprintLocationID').value = document.querySelector('.selectedColorText').getAttribute('productimprintlocationID');
		 	}
		};
		return{
			Init: init
		};
	})(),
	
	formEvents = (function(){
		var completeCheck = '<svg class="textDarkestGray show" height="20px" width="20px" aria-label="completed step"> <use xlink:href="#svgIcon-checkMark"></use> </svg>'; 
		var productInfo = document.querySelectorAll(".productCopy");
		var cartPanel = document.querySelectorAll("#cartPanel");
		var detailCopy = document.querySelectorAll(".detailCopy");
		
	    	var stepOneComplete = function(){
	    		document.getElementById("panelOne").classList.add("bkgdGreen");
	        	document.getElementById("panelOne").classList.remove("bkgdMdGray");
	        	document.getElementById("panelOne").innerHTML = completeCheck;
	        	document.querySelector(".panelOne").querySelector("h2").querySelector(".italic").classList.add("hide"); 
	        	if(document.querySelector(".selectedProdColor")){
            			document.getElementById("ItemColor").innerHTML = document.querySelector(".selectedProdColor").querySelector(".colorInfo").textContent;
            		}else{
            			document.getElementById('selectedColorID').value = document.querySelector("#selectedColor0").parentNode.querySelector(".stockAvailable").getAttribute("title");
            			document.getElementById("ItemColor").innerHTML = document.querySelector("#selectedColor0").textContent;
            		}
	    	},
	    	stepTwoComplete = function(){
	    		document.getElementById("panelTwo").classList.add("bkgdGreen");
	        	document.getElementById("panelTwo").classList.remove("bkgdMdGray");
	        	document.getElementById("panelTwo").innerHTML = completeCheck;
	        	if(document.querySelector(".stealadeal")){
	        		document.querySelector(".stealadeal").classList.add("show");
	        		document.querySelector(".stealadeal").classList.remove("hide");
	        	}else{
	        		document.getElementById("ItemQuantity").textContent = document.getElementById("txtQty").value;
	        	}
	    	},
	    	stepThreeComplete = function(){
	    		document.getElementById("panelThree").classList.add("bkgdGreen");
	        	document.getElementById("panelThree").classList.remove("bkgdMdGray");
	        	document.getElementById("panelThree").innerHTML = completeCheck;
	        	var panelThreeLocations = [].slice.call(document.querySelector(".panelThree").querySelectorAll("h3"));
	        	panelThreeLocations.forEach(function(el){
	        		el.querySelector(".italic").classList.add("hide");
	        	});
	    	},
	    	stepFourComplete = function(){
	    		document.getElementById("panelFour").classList.add("bkgdGreen");
			document.getElementById("panelFour").classList.remove("bkgdMdGray");
			document.getElementById("panelFour").innerHTML = completeCheck;
	    	},
	    	updateStepsComplete = function(){
	    		//step 1 complete
	    		if(document.querySelector(".selectedProdColor") || document.getElementById("singleStockPanel")){
	    			stepOneComplete();
			}
			//step 2 glide quantity complete
			if (document.querySelector(".glideSlider")){
				stepTwoComplete();
			}
			//step 3 complete
			if(document.querySelector(".selectedImprintColor") || document.querySelector(".selectedColorText").innerHTML !== ""){
				stepThreeComplete();
			}
			//steps 1, 2, 3 complete
			if(document.getElementById("panelOne").classList.contains("bkgdGreen") && document.getElementById("panelTwo").classList.contains("bkgdGreen") && document.getElementById("panelThree").classList.contains("bkgdGreen")){
			  	stepFourComplete();
			}
	    	},
	    	mobileTweaks = function(){
			moveProductInfo();
			if (document.querySelector(".glideSlider")){
				sliderGlide.UpdateToolTip();
			}
		},
		moveProductInfo = function(){
			if(window.innerWidth >= 800 && productInfo[0].parentNode.id !== "ProductOrderPanel"){
				// product info goes before cartPanel element
	        		cartPanel[0].parentNode.insertBefore(productInfo[0], cartPanel[0]);
			}else if(window.innerWidth < 800 && !productInfo[0].previousElementSibling){
				// product info goes before detailCopy element
	        		detailCopy[0].parentNode.insertBefore(productInfo[0], detailCopy[0]);
			}
		},
		focusOnFirstColor = function(e){
			e.preventDefault();
			document.querySelector(".panelOne").querySelector(".colorOption").focus();
		},
		focusOnQuantity = function(e){
			e.preventDefault();
			if(document.getElementById("GlideSlider")){
				document.querySelector(".panelTwo").querySelector("#GlideSlider").focus();
			}else{
				document.querySelector(".stealadeal").focus();
			}
		},
		focusOnSAD = function(e){
			e.preventDefault();
			document.querySelector(".panelTwo").querySelector(".stealadeal").focus();
		},
		focusOnImprintColor = function(e){
			e.preventDefault();
			document.querySelector(".panelThree").querySelector("a").focus();
		},
		removeErrorBox = function(){
			if(document.getElementById("outletErrorContainer")){
				//document.getElementById("outletErrorContainer").remove(); // remove() does not work in IE
				document.getElementById("outletErrorContainer").parentNode.removeChild(document.getElementById("outletErrorContainer"));
			}
		},
		showErrorBox = function(errors){
			removeErrorBox(); 			
			var errorBox = 
				'<div id="outletErrorContainer" class="panelMain">'+
					'<div id="outletErrorBox" class="ErrorMsgPanel" role="alert" aria-atomic="true">' +
		                        	'<h3 class="validationHeading">Please correct the following errors and resubmit:</h3>' +
		                        	'<ul id="outletErrorMessage">' + errors + '</ul>' +
		                    '</div>'+
	                    '</div>';
	              
			document.getElementById("cartPanel").insertAdjacentHTML("afterbegin", errorBox);
			
			// add click events to focus on portions of page from error box
			if(document.getElementById("itemColorError")){
				document.getElementById("itemColorError").removeEventListener("click", focusOnFirstColor);
				document.getElementById("itemColorError").addEventListener("click", focusOnFirstColor);
			}
			if(document.getElementById("quantityError")){
				document.getElementById("quantityError").removeEventListener("click", focusOnQuantity);
				document.getElementById("quantityError").addEventListener("click", focusOnQuantity);
			}
			if(document.getElementById("stealADealError")){
				document.getElementById("stealADealError").removeEventListener("click", focusOnSAD);
				document.getElementById("stealADealError").addEventListener("click", focusOnSAD);
			}
			if(document.getElementById("imprintColorError")){
				document.getElementById("imprintColorError").removeEventListener("click", focusOnImprintColor);
				document.getElementById("imprintColorError").addEventListener("click", focusOnImprintColor);
			}

			document.getElementById("outletErrorMessage").querySelector("a").focus();
			window.scrollTo({ top: 0, behavior: "smooth" });
			
		};

		return {
			UpdateStepsComplete: updateStepsComplete,
			MobileTweaks: mobileTweaks,
			MoveProductInfo: moveProductInfo,
			ShowErrorBox: showErrorBox,
			RemoveErrorBox: removeErrorBox
		};
	})(),
	
	ajaxActions = (function(){
		var antiForgeryToken = { "RequestVerificationToken": document.querySelectorAll('input[name=__RequestVerificationToken]')[0].value },
		verificationToken = document.querySelectorAll('input[name=__RequestVerificationToken]')[0].value,
		
		getSADNotifyForm = function(){
			fetch('/product/stealadealnotify', { headers: { 'x-requested-with': 'fetch' }}).then(function(response){
				return  (response.ok) ? response.text() : '';
			}).then(function(html){
				if(html.length < 1) { return; }
				document.getElementById('stealADealNotify').innerHTML = html;
				if(document.getElementById("notifyButton")){
					document.getElementById("notifyButton").addEventListener("click", function(e){
						e.preventDefault();
						forms.formValidation(document.getElementById("signUpForm"), ajaxActions.NotifyMe, e);
					});
				}				
			});					
		},
		
		getSimilarItemScroller = function(){
			const token = document.querySelectorAll('input[name=__RequestVerificationToken]')[0].value ;
			var data = { productID: document.getElementById('ProductID').value  };
			fetch('/product/similaritemsscroller',  { 
				method: 'POST',
				credentials: 'same-origin',
				headers: { 'Content-Type': 'application/json', 'x-requested-with': 'fetch', 'RequestVerificationToken': token },
				body: JSON.stringify(data)
			}).then(function(response){
				return  (response.ok) ? response.text() : '';
			}).then(function(html){
				if(html.length < 1) { return; }
				document.getElementById('similarScroller').innerHTML = (html);
				initializeScroller("similarScroller");
				// todo image error init?
			});				
			
		};		
		
		notifyMe = function(){
			var email = document.getElementById("notifyEmail").value;
			var productID = document.getElementById("ProductID").value;
				        			
			fetch('/sad/stealdealnotify', { 
				method: 'POST', 
				body: JSON.stringify({ productID: parseInt(productID), email: email }), 
				headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch', "RequestVerificationToken": verificationToken }
			}).then(function(response){
				return  (response.ok) ? response.text() : []; 
			}).then(function(response){
				document.getElementById("signUpForm").classList.add("hide");
				document.getElementById("signUpForm").insertAdjacentHTML("beforebegin", '<p class="notifySuccess textSemiBold textBlue marginTop15" role="alert" aria-atomic="true">Congrats! You will be notified when this deal can be stolen.</p>');
			}).catch(function(error){
				// place error handling here
			});
		},
		addToCart = function (e) {
        		e.preventDefault();
        		if(document.querySelector(".addToCartError")){
        			document.querySelector(".addToCartError").parentNode.removeChild(document.querySelector(".addToCartError"));
        		}
			var productId = document.getElementById('ProductID');
			var stealADealId = document.getElementById('sadID') ? document.getElementById('sadID') : "";
			var selectedColorID = document.getElementById('selectedColorID');
			var isStealADeal = document.getElementById('isStealADeal') ? document.getElementById('isStealADeal') : "";
			var quantity = document.getElementById('ItemQuantity');
			var imprintColorID = document.getElementById('ImprintColorID');
			var imprintLocationID = document.getElementById('ImprintLocationID');
			var productImprintLocationID = document.getElementById('ProductImprintLocationID');
			var editId =document.getElementById('EditId');
   			var baseColorId = -1;
   			var trimColorId = -1;
   			if(selectedColorID.value != null && selectedColorID.value != ''){
   				var baseTrim = selectedColorID.value.split('/');
   				if(baseTrim != null && baseTrim.length === 2){
   					baseColorId = baseTrim[0];
   					trimColorId = baseTrim[1];
   				}
   			} 
   			var addToCartParams = {
				ProductId: parseInt(productId.value),
				StealADealId: stealADealId.value,
				Quantity: parseInt(quantity.innerHTML),
				BaseColorId: parseInt(baseColorId),
				TrimColorId: parseInt(trimColorId),
				ImprintColorId: parseInt(imprintColorID.value),
				ImprintLocationId: parseInt(imprintLocationID.value),
				ProductImprintLocationId: parseInt(productImprintLocationID.value),
				EditId: parseInt(editId.value),
				IsStealADeal: isStealADeal.value === 'true'
   			};  
      			
    			if (document.getElementById("panelFour").classList.contains("bkgdGreen")) {
    				//If no color was selected alert the user.
    				if (selectedColorID.value === "") {
            				var selectColorError = '<li><a id="itemColorError" class="errorItem" href="#" tabindex="0" role="link">Please choose an item color.</a></li>';
    					formEvents.ShowErrorBox(selectColorError);
            				return;
    				}
    				formEvents.RemoveErrorBox();
				document.getElementById("cartButton").insertAdjacentHTML("afterend", "<div id='loadingAddCart' class='spinner paddingTop10' role='alert' aria-busy='true' aria-atomic='true' aria-label='Adding to cart, please wait'><div class='bounce1'></div><div class='bounce2'></div><div class='bounce3'></div></div>");
	        			
				fetch('/cart/AjaxAddToCart', { 
					method: 'POST', 
					body: JSON.stringify(addToCartParams), 
					headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch', "RequestVerificationToken": verificationToken }
				}).then(function(response){
					return  (response.ok) ? response.json() : []; // response.json() returns a promise
				}).then(function(response){
					if(response.success){
            					omniture.LogAddToCart(response.newCart == undefined ? true : response.newCart);
            				}
            				window.location.href= '/cart';
				}).catch(function(error){
					// place error handling here
				});
    			}else{
    				var newErrors = "";
	    			if (document.getElementById("panelOne").classList.contains("bkgdMdGray")) {
	    				newErrors += '<li><a id="itemColorError" class="errorItem" href="#" tabindex="0" role="link">Please choose an item color.</a></li>';
	    			}
	    			if (document.getElementById("panelTwo").classList.contains("bkgdMdGray") && !isStealADeal) {
	    				newErrors += '<li><a id="quantityError" class="errorItem" href="#" tabindex="0" role="link">Please select the quantity.</a></li>';
	    			}
	    			if (document.getElementById("panelTwo").classList.contains("bkgdMdGray") && isStealADeal.value === 'True') {
	        			newErrors += '<li><a id="stealADealError" class="errorItem" href="#" tabindex="0" role="link">Click "make an offer" to "steal a deal™"</a></li>';
	    			}
	    			if (document.getElementById("panelThree").classList.contains("bkgdMdGray")) {
	    				newErrors += '<li><a id="imprintColorError" class="errorItem" href="#" tabindex="0" role="link">Please choose an imprint color.</a></li>';
	    			}
    				formEvents.ShowErrorBox(newErrors);
    			}
		},
		
		omniture = (function() {
			var logAddToCart = function(newCart){
				const st = typeof s_gi === 'function' ? s_gi(s.account) : '';
				if(st)
				{					
           		 		st.events = (newCart) ? 'scOpen, scAdd' : 'scAdd';
			 		st.tl();
			 	}			 			 	
			 };
			 
			 return {
			 	LogAddToCart: logAddToCart
			 }
        	}());
		
		return{
			GetSADNotifyForm: getSADNotifyForm,
			GetSimilarItemScroller: getSimilarItemScroller,
			NotifyMe: notifyMe,
			AddToCart: addToCart
		};
	})(),
	
    	init = function(){	
		if(!itemSoldOut){
			if (isGlide) {
				glide.Init(arrBreak, arrPrice, steppingQty);
				sliderGlide.Init();
			}
	
			var imprintLocations = [].slice.call(document.querySelectorAll(".imprintContainer"));
			imprintLocations.forEach(function(el, index, array){
				imprintColorPanel.Init(el);
			});
			
			productColorPanel.Init();
			formEvents.UpdateStepsComplete();
			
			if(document.querySelector(".addToCartBtn")){
				document.querySelector(".addToCartBtn").addEventListener("click", ajaxActions.AddToCart);
			}
			
			if(document.getElementById("offerQuantity")){ //previously stolen, needs to update quantity selected
				document.getElementById("ItemQuantity").textContent = document.getElementById("offerQuantity").textContent;
			}
		}
		
 		formEvents.MoveProductInfo();
 		window.addEventListener("resize", formEvents.MobileTweaks);

		initFocus();//initialize dom observer
		/* This is now loaded via ajax
		if(document.getElementById("similarScroller")){
			initializeScroller("similarScroller");
		}*/
	};
	
	return {
		Init: init
	};
};
 
(function(){
	var details = new productDetails();
	details.Init();
})();