var sadNegotiator = (function () {
	let forms = new forms4i(),
	loadingIcon = '<div class="spinner" role="alert" aria-busy="true" aria-atomic="true" aria-label="Loading, please wait"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div>',
	logInBtn = document.getElementById("stealadealAnon"),
	
	productID = parseInt(document.getElementById("ProductID").value),
	customerAcceptedOffer = "false",
	makeOfferBtn, panelTwo, panelOneNumber, panelTwoNumber, panelThreeNumber, panelFourNumber, sadID, highestOutletPrice,
	offerData, priceOffered, loginModal, sadModal, popUpDialogEl, popUpDialogContent, endTime,

	initOfferPanel = () => {
		detailSteps.GetElements();
		
		if(logInBtn) {
			if(loginModal) loginModal.BindTriggerElements(document.getElementById("stealadealAnon"));
			else loginDialog.Init();
		}
		
		if(makeOfferBtn) {
			if(!sadModal) sadDialog.Init();
			makeOfferBtn.addEventListener("click", begin);
		}
		
		endTime = document.getElementById("endTime");
		if(endTime && endTime.value != null) countdownTimer.Init();
	},
	begin = (e) => {
		e.preventDefault();
		const error = document.getElementById("outletErrorContainer");
		if(error) error.remove();
		
    		if (panelOneNumber.classList.contains("bkgdGreen")) sadDialog.OpenInitial();
    		else detailSteps.StepOneError();
	},
	showSadError = () => {
		const sadError = '<p class="sadError text18 textSemiBold textDkRed marginBtm20" role="alert" aria-atomic="true">Oops! There was an error with stealing the deal. Please <a href="/info/contactus">contact us</a> if you continue to have trouble.</p>';
		panelTwo.querySelector(".panelMain").insertAdjacentHTML("afterend", sadError);
		sadModal.Close();
	},
	formatCurrency = (num) => {
		num = isNaN(num) || num === '' || num === null ? 0.00 : num;
		return '$' + parseFloat(num).toFixed(2);
	},
	
	detailSteps = (function(){ 
		let getElements = () => {
			makeOfferBtn = document.getElementById("dealStealer");
			panelTwo = document.querySelector(".panelTwo");
			panelOneNumber = document.querySelector(".panelOne") ? document.querySelector(".panelOne").querySelector(".panelNumber") : null;
			panelTwoNumber = panelTwo ? panelTwo.querySelector(".panelNumber") : null;
			panelThreeNumber = document.querySelector(".panelThree") ?  document.querySelector(".panelThree").querySelector(".panelNumber") : null;
			panelFourNumber = document.querySelector(".panelFour") ? document.querySelector(".panelFour").querySelector(".panelNumber") : null;
		},
		refreshStepTwo = async () => {
			let responseHTML;
			let offerAccepted = customerAcceptedOffer == "true" ? true: false;
			const data = { productID: productID, customerAcceptedOffer: offerAccepted, sadID: sadID };
			try{
				const response = await fetch('/sad/reloadcartpanel', {
					method: 'POST', 
					body: JSON.stringify(data),
					headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch' }
				});
				//if(!response.ok) throw new Error("Error processing refresh SAD panel request");
				responseHTML = await response.text();
			}catch(error){
				console.log(error);
				showSadError();
			}
			
			if(responseHTML) stepTwoContent(responseHTML);
		},
		stepTwoContent = (panelHTML) => {
			panelTwo.innerHTML = panelHTML;
			getElements();
			if(customerAcceptedOffer == "true") markStepComplete(panelTwoNumber);
        	 	confetti.RemoveHtml();
        	 	initOfferPanel();
        	 	sadModal.Close();
        	 	setTimeout(() => { setFocus(); }, 200);
		},
		stepOneError = () => { 
			const stealError = document.querySelector(".stealError");
			stealError.classList.remove("hide");
			const firstColorOpt = document.querySelector(".colorOption");
	            	firstColorOpt.focus();
		},
		markStepComplete = (pnl) => {
			pnl.classList.add("bkgdGreen");
			pnl.classList.remove("bkgdMdGray");
			pnl.innerHTML = '<svg class="textDarkestGray show" height="20px" width="20px" aria-label="completed step"> <use xlink:href="#svgIcon-checkMark"></use> </svg>';
		},
		setFocus = () => {
			if(panelTwoNumber.classList.contains("bkgdMdGray")){
				makeOfferBtn = document.getElementById("dealStealer");
				makeOfferBtn.focus();
			}else if(panelThreeNumber.classList.contains("bkgdMdGray")){ 
				const imprintColor = document.querySelector(".impColContainer").querySelector("button");
				imprintColor.focus();
			}else{
				markStepComplete(panelFourNumber);
				const cartBtn = document.getElementById("cartButton");
				cartBtn.focus();
			}
		};
		return{
			GetElements: getElements,
			RefreshStepTwo: refreshStepTwo,
			StepOneError: stepOneError
		};
	})(),
	
	loginDialog = (function(){
		let stealDealUrl,
		init = () => {
			stealDealUrl = logInBtn.getAttribute("href");
			
			loginModal = new Dialog4i();
			loginModal.Init({
				triggerElements: logInBtn,
				dialogClass: "smallDialog", 
				title: "Register", 
				content: getContent()
			});
		},
		getContent = () => {
			return '<p id="registerDesc" class="textSemiBold paddingBtm10">You must be a registered user to steal a deal&trade;</p>' +
				'<a id="registerStealADeal" class="bkgdWhite button-md text16" href="' + stealDealUrl + '">Sign In</a>';
		};
		return{
			Init: init
		};
	})(),
	
	sadDialog = (function(){		
		let init = () => {
			sadModal = new Dialog4i();
			sadModal.Init({
				triggerElements: [],
				dialogClass: "stealADealDialog sadDialog",
				title: "<span class='visually-hidden'>Steal a Deal Dialog</span>",
				content: loadingIcon,
				onOpen: open,
				onClose: close
			});
		},
		open = () => {
			popUpDialogEl = document.getElementById("popUpDialog");
			popUpDialogContent = popUpDialogEl.querySelector(".dialogContent");
			
			addDialogHdrLogo();
			popUpDialogEl.addEventListener("click", events);
		},
		close = () => {
			detailSteps.RefreshStepTwo();
		},
		addDialogHdrLogo = () => {
			// customize dialog by adding the confetti image on top
			let hdrConfetti = '<img src="//cdn.4imprint.com/qtz/outlet/images/confetti.svg" alt="" height="60" width="192" />';
			let sadLogo = '<svg class="largeTag" height="77px" width="120px" role="img" aria-label="Steal a Deal"><title>Steal a Deal</title><use xlink:href="#svgLogo-sad"></use></svg>';
			const topHtml = '<div class="confettiHeader posRelative displayFlex flexColumn flexAlignItemsCenter">' + hdrConfetti + sadLogo + '</div>';
			
			const mainDialog = document.querySelector(".stealADealDialog");
			mainDialog.insertAdjacentHTML("beforebegin", topHtml);
		},
		openInitial = async () => {
			const error = document.querySelector(".sadError");
			if(error) error.remove();
			sadModal.Open();
				
			let responseHTML;
			const data = { productID: productID };
			try{
				const response = await fetch('/sad/stealdealfirst', {
					method: 'POST', 
					body: JSON.stringify(data),
					headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch' }
				});
				//if(!response.ok) throw new Error("Error processing initial SAD dialog request");
				responseHTML = await response.text();
			}catch(error){
				console.log(error);
				showSadError();
			}
			
			if(responseHTML){
				popUpDialogContent.innerHTML = responseHTML;
				quantities.Init();
				sadID = document.getElementById("sadID").value;
				highestOutletPrice = document.getElementById("HighestPrice").textContent;
			}
		},
		events = (e) => {
			if(e.target.id == "makeOffer"){
				e.preventDefault();
				quantities.Validate(e);
			}else if(e.target.classList.contains("okayButton")){
				e.preventDefault();
				customerAcceptedOffer = "true";
				close();
			}else if(e.target.id == "acceptOffer"){
				e.preventDefault();
				customerAcceptedOffer = "true";
				close();
			}else if(e.target.id == "makeNewOffer"){
				e.preventDefault();
				quantities.Calc();
    				makeOffer.Second();
			}else if(e.target.id == "makeFinalOffer"){
				e.preventDefault();
				quantities.Calc();
	   			makeOffer.FinalOffer();
			}
		};
		
		return{
			Init: init,
			OpenInitial: openInitial
		};
	})(),
	
	confetti = (function(){
		let confettiWrapper,
		checkDisplay = () => {
			const dealSuccess = document.getElementById("dealIsStolen");
			if(dealSuccess){
				confettiWrapper = document.querySelector(".confettiWrapper");
				if(confettiWrapper) confettiWrapper.remove();
				
				// create confetti wrapper div in the dialog
				const confettiDiv = '<div class="confettiWrapper posAbsolute"></div>';
				popUpDialogEl.insertAdjacentHTML("beforebegin", confettiDiv); 
				confettiWrapper = document.querySelector(".confettiWrapper");
				for (let i = 0; i < 150; i++) {
	    				create(i);
				}
			}
		},
		create = (i) => {
			// 5 = number of different confetti types
			var colorIdx = Math.ceil(Math.random() * 5);
			var confettiType = "whiteSquiggle";
			switch(colorIdx) {
		            	case 1:
		            		confettiType = "yellowCircle";
		            		break;
		            	case 2:
		            		confettiType = "yellowCircleLarge";
		            		break;
		            	case 3:
		            		confettiType = "orangeStar";
		            		break;
		            	case 4:
		            		confettiType = "orangeStarLarge";
			}
			// create the random confetti elements
			const confettiEl = document.createElement("div");
			confettiEl.classList.add("confetti-" + i);
			confettiEl.classList.add(confettiType);
			confettiEl.style.left = Math.random()*2000 + "px";
			confettiEl.style.transform = "rotate(" + Math.random()*360 + "deg)";
			confettiEl.style.animationDuration = (1 + 0.03*Math.random()*200)*1 + "s"; // can change the .03 to change length of animation
			
			// append all of the confettis to the confetti wrapper element
			confettiWrapper.append(confettiEl);
			setTimeout(() => { removeHtml(); }, 3000); // after 3 seconds, remove the confetti
		},
		removeHtml = () => {
			confettiWrapper = document.querySelector(".confettiWrapper");
			if(confettiWrapper) confettiWrapper.remove();
		};
		return{
			CheckDisplay: checkDisplay,
			RemoveHtml: removeHtml
		};
	})(),	

	countdownTimer = (function(){
		let CountdownInterval = 0,
		EndCountdownTime = 0,
		CountdownTimerElement,
		TimeDiff = 0,
		StartCountdownTime,

		init = () => {
			if(document.getElementById("requestTime")){
	    			StartCountdownTime = new Date(document.getElementById("requestTime").value);
	    			EndCountdownTime = new Date(document.getElementById("endTime").value);
	    			TimeDiff = (EndCountdownTime.getTime() - StartCountdownTime.getTime()) / 1000;
	    			if (TimeDiff > 0) {
	        			updateTimeLeft();
	        			CountdownInterval = setInterval(updateTimeLeft, 1000);
	    			}
	    		}
		},
		updateTimeLeft = () => {
			if (TimeDiff <= 0){
	    			clearInterval(CountdownInterval);
	    		}else {
	        		var HoursLeft = (Math.floor((TimeDiff / 60) / 60)).toString();
	        		var MinutesLeft = (Math.floor((TimeDiff / 60) % 60)).toString();
	        		var SecondsLeft = (Math.floor(TimeDiff % 60)).toString();
	        		if (HoursLeft == 1 && MinutesLeft == 0 && SecondsLeft == 0) {
	            			//UpdateDeals(sadID); //TODO
	            			return;
	        		}
	        		if (HoursLeft.length < 2) { HoursLeft = '0' + HoursLeft; }
	        		if (MinutesLeft.length < 2) { MinutesLeft = '0' + MinutesLeft; }
	        		if (SecondsLeft.length < 2) { SecondsLeft = '0' + SecondsLeft; }
	        		
	        		CountdownTimerElement = document.getElementById("countdownTime");
	        		if (HoursLeft == 0) {
	            			if (MinutesLeft == 0) {
	                			CountdownTimerElement.innerHTML = '00:00:' + SecondsLeft;
	            			}else {
	                			CountdownTimerElement.innerHTML = '00:' + MinutesLeft + ':' + SecondsLeft;
	            			}
	        		}else {
	            			CountdownTimerElement.innerHTML = HoursLeft + ":" + MinutesLeft + ':' + SecondsLeft;
	        		}
	        		TimeDiff = TimeDiff - 1;
	    		}
		};
		return{
			Init: init
		};
	})(),
	
	quantities = (function(){ 
		let QtyInputTimer, lowQty, maximumQty,

		init = () => {
			lowQty = lowestQty; // in VS
			maximumQty = maxQty; //in VS
		
			const offerQtyBox = document.getElementById("offerQuantity");
			if(offerQtyBox){
				enforceMin();
				enforceStepping();	
			}
			
			addEvent();
			
			const qtyPrice = document.getElementById('qtypricemain');
			const priceTable = document.getElementById('originalpricetable');
			if(qtyPrice) priceTable.innerHTML = qtyPrice.innerHTML;
		},
		addEvent = () => {
			const offerPriceBox = document.getElementById("offerPrice");
			if(offerPriceBox) offerPriceBox.addEventListener("keyup", updatePrice);
		},
		updatePrice = () => {
        		const qty = document.getElementById("offerQuantity").value;
			let offerPriceElement = document.getElementById("offerPrice");
			const offerPrice = offerPriceElement.value;
			const noticeElement = document.getElementById("highOffer");
                	const maxprice = parseFloat(document.getElementById("HighestPriceHide").textContent);

        		offerPrice.replace(/[$,]+/g, "");
        		const offerTotal = document.getElementById("offerTotal");
        		let result;
                	if (offerPrice > maxprice){
				result = parseFloat(maxprice * qty);
				offerPriceElement.value = maxprice;
				offerTotal.innerHTML = formatCurrency(result);
				noticeElement.innerText = "Offer entered is greater than the original amount";
			}else {
				result = parseFloat(offerPrice * qty);
				offerTotal.innerHTML = formatCurrency(result);
				noticeElement.innerText = "";
			}
		},
		checkQtyTimer = (e) => {
	      		if (QtyInputTimer != null) clearTimeout(QtyInputTimer);
	      		const updateTime = 750;
	      		QtyInputTimer = setTimeout(() => { updateQuantity(e); }, updateTime);
		},	
		removeGlideError = () => {
			const qtyError = document.getElementById("glideQtyErrorAlert");
			if(qtyError) qtyError.remove();
		},
		updateQuantity = (e) => {
			removeGlideError();
			const offerDetails = document.querySelector('#offerDetails');
			if(e.target.value < lowQty){
				const minQuantityErrorHtml = '<div class=\"alertBoxFlex displayFlex flexNoWrap\" id="glideQtyErrorAlert" role="alert" aria-atomic="true"><svg class="alertBulb" height="25px" width="25px" aria-hidden="true"><use xlink: href="#svgIcon-hint"></use></svg><div class="alertText">We\'re sorry, the minimum quantity is ##MINQTY## for this item. The amount you entered has been adjusted. </div></div>';
				e.target.value = lowQty;
				offerDetails.insertAdjacentHTML('beforebegin', minQuantityErrorHtml.replace('##MINQTY##', lowQty));
	    		} else if(e.target.value > maximumQty){
	    			const maxQuantityErrorHtml = '<div class=\"alertBoxFlex displayFlex flexNoWrap\" id="glideQtyErrorAlert" role="alert" aria-atomic="true"><svg class="alertBulb" height="25px" width="25px" aria-hidden="true"><use xlink: href="#svgIcon-hint"></use></svg><div class="alertText">We\'re sorry, the maximum quantity is ##MAXQTY## for this item. The amount you entered has been adjusted. </div></div>';
	    			e.target.value = maximumQty;
	    			offerDetails.insertAdjacentHTML('beforebegin', maxQuantityErrorHtml.replace('##MAXQTY##', maximumQty));
	    		} else {
	    			const glide = new Glide4imprint();
	    			const steppingQty = document.getElementById("steppingQty");
	    			const qty = Math.round(e.target.value);
	    			const glideQty = glide.EnforceSteppingQty(parseInt(qty), parseInt(steppingQty.value));
	    			e.target.value = glideQty;
	    		}
		},
		enforceStepping = () => {
			const steppingQty = document.getElementById("steppingQty");
	    		if(steppingQty.value > 0){
	    			const offerQtyBox = document.getElementById("offerQuantity");
	    			offerQtyBox.addEventListener("keyup", checkQtyTimer);
	    			offerQtyBox.addEventListener("change", checkQtyTimer);	    		
	    		}
		},
		enforceMin = () => {
			const input = document.getElementById("offerQuantity");
			if(input.value < lowQty) input.value = lowQty;
		},
		calc = () => {		
			const qty = document.getElementById("offerQuantity");
	    		let counterOffer = document.getElementById("counterOffer").innerHTML;
	    		priceOffered = document.getElementById("offerPrice").value;
	    		
	    		counterOffer = counterOffer.replace("$", "");
	    		if (counterOffer.indexOf("¢") > -1) {
	        		counterOffer = counterOffer.replace("¢", "");
	        		counterOffer = "." + counterOffer;
	    		}
	   		offerData = { counterOffer: counterOffer, offerQuantity: qty.value, priceOffered: priceOffered, productID:productID, glidePrice: highestOutletPrice };
    			if(!popUpDialogEl) sadModal.Open();  
		},
		validate = (e) => {
			const offerQuant = document.getElementById("offerQuantity");
			const quantAvailable = document.querySelector(".selectedProdColor") ? document.querySelector(".selectedProdColor").querySelector(".stockAvailable") : document.querySelector(".stockAvailable");
			const minimumQuantity = document.getElementById("minVal").value;
			
			if(offerQuant.classList.contains("input-validation-error")) removeQuantError();
			
			if(parseInt(offerQuant.value) > parseInt(quantAvailable.textContent)){
				// don't let user order more than what is available in that color
				showError("There's only " + quantAvailable.textContent + " left in the color selected.");
			}else if(parseInt(offerQuant.value) < parseInt(minimumQuantity)){
				// don't let user order less than the order minimum
				showError("The minimum order quantity is " + minimumQuantity);
			}else{
				const sadForm = document.getElementById("sadForm");
				forms.formValidation(sadForm, checkQty, e);
			}
		},
		removeQuantError = () => {
			const offerQuant = document.getElementById("offerQuantity");
			const errorMsg = offerQuant.parentNode.querySelector(".errorMessage");
			offerQuant.classList.remove("input-validation-error");
			offerQuant.setAttribute("aria-invalid", "false");
			errorMsg.textContent = "";
			errorMsg.classList.add("hide");
		},
		showError = (errorText) => {
			const offerQuant = document.getElementById("offerQuantity");
			const errorMsg = offerQuant.parentNode.querySelector(".errorMessage");
			offerQuant.classList.add("input-validation-error");
			offerQuant.setAttribute("aria-invalid", "true");
			errorMsg.setAttribute("role", "alert");
			errorMsg.setAttribute("aria-atomic", "true");
			errorMsg.textContent = errorText;
			errorMsg.classList.remove("hide");
		},
		checkQty = async () => { 
	    		const catalogAlias = document.getElementById("CatalogAlias").value;
	    		let quantity = document.getElementById("offerQuantity").value;
	    		const tableQty = document.getElementById("tableOfferQuantity");
	    		if(!quantity || quantity === "") quantity = tableQty.innerHTML;
	    		
	    		priceOffered = document.getElementById("offerPrice").value;
	    		const baseTrimColorID = document.getElementById("selectedColorID").value;
	    		highestOutletPrice = document.getElementById("HighestPrice").textContent;
	    		
	    		const itemQty = document.getElementById("ItemQuantity");
	    		itemQty.innerHTML = quantity;
	    		
			let responseHTML;
	 		let data = { catalogAlias: catalogAlias, quantity: parseInt(quantity), productID: productID, baseTrimColorID: baseTrimColorID };
			try{
				const response = await fetch('/sad/checkquantity', {
					method: 'POST', 
					body: JSON.stringify(data),
					headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch' }
				});
				//if(!response.ok) throw new Error("Error processing quantity check request");
				responseHTML = await response.text();
			}catch(error){
				console.log(error);
				showSadError();
			}
			
			if (responseHTML !== "" && responseHTML !== null) {
				if(typeof responseHTML !== "function") popUpDialogContent.innerHTML = responseHTML;
			}else {
				makeOffer.Review();
            			data = { catalogAlias: catalogAlias.replace('#', ''), quantity: parseInt(quantity), priceOffered: priceOffered, productID: productID, baseTrimColorID: baseTrimColorID, highestOutletPrice: highestOutletPrice };
				makeOffer.First(data, quantity);
			}
		},
		initNew = (qty, priceOffered, counterOffer) => {
			addEvent();

			const newQty = document.getElementById("offerQuantity");
            		if(newQty) newQty.value = qty;
            		
            		const sadOffer = document.querySelector(".sadOffer");
        		if(sadOffer) sadOffer.textContent = formatCurrency(priceOffered);
        		
        		const tableTotal = document.getElementById("tableOfferTotal");
        		if(tableTotal){
        			const totalPrice = parseFloat(counterOffer * qty);
        			tableTotal.textContent = formatCurrency(totalPrice);
        		}
			popUpDialogEl.querySelector(".close4iDialog").focus();
		};
		/*enforceMax = function(){
			const input =document.getElementById("offerQuantity");
			if(input.value > maximumQty) input.value = maximumQty;
		};*/
		return{
			Init: init,
			AddEvent: addEvent,
			Calc: calc,
			Validate: validate,
			InitNew: initNew
		};
	})(),
	
	makeOffer = (function(){
		let first = async (data, quantity) => {
			let responseHTML;
			try{
				const response = await fetch('/sad/makeoffer', {
					method: 'POST', 
					body: JSON.stringify(data),
					headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch' }
				});
				//if(!response.ok) throw new Error("Error processing making an offer request");
				responseHTML = await response.text();
			}catch(error){
				console.log(error);
				showSadError();
			}
			
			if(responseHTML && typeof responseHTML !== "function") showSecondOffer(responseHTML, quantity);
		},
		showSecondOffer = (offerHTML, quant) => {
            		sadModal.ShowCloseBtn();
			popUpDialogContent.innerHTML = offerHTML;
			sadID = document.getElementById("sadID").value;
            		quantities.AddEvent();
			
			if(document.getElementById("dealIsStolen")) sadModal.HideCloseBtn();

            		const offerQty = document.getElementById("offerQuantity");
            		if(offerQty) offerQty.value = quant;
			
			confetti.CheckDisplay();
			popUpDialogEl.querySelector(".close4iDialog").focus();
		},
		second = async () => {
    			let responseHTML;
			try{
				const response = await fetch('/sad/stealdealsecond', {
					method: 'POST', 
					body: JSON.stringify(offerData),
					headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch' }
				});
				//if(!response.ok) throw new Error("Error processing second offer request");
				responseHTML = await response.text();
			}catch(error){
				console.log(error);
				showSadError();
			}
			
			if(responseHTML) {
				sadModal.ShowCloseBtn();
				showFinalContent(responseHTML);
			}
		},
		showFinalContent = (offerHTML) => {
			popUpDialogContent.innerHTML = offerHTML;
			sadID = document.getElementById("sadID").value;
			quantities.InitNew(offerData.offerQuantity, offerData.priceOffered, offerData.counterOffer);
		},
		finalOffer = async () => {
	   		let responseHTML;
	   		console.log(offerData);
			try{
				const response = await fetch('/sad/stealdealfinal', {
					method: 'POST', 
					body: JSON.stringify(offerData),
					headers: { 
						'Content-Type': 'application/json', 
						'X-Requested-With': 'fetch' 
					}
				});
				//if(!response.ok) throw new Error("Error processing request");
				responseHTML = await response.text();
			}catch(error){
				console.log(error);
				showSadError();
			}
			
			if(responseHTML) showFinalContent(responseHTML);
		},
		review = () => {
			const reviewHTML = '<h2 id="sadLabel" class="text20 textBold textCenter marginTop40">Make An Offer</h2>' +
            			'<p id="sadDesc" class="text25 textCenter marginTop20 marginBtm20">Reviewing Your Offer</p>' +
	            		loadingIcon +
				'<div class="smallSpace"></div>';
				
			sadModal.HideCloseBtn();
			popUpDialogContent.innerHTML = reviewHTML;
		};
		
		return{
			First: first,
			Second: second,
			FinalOffer: finalOffer,
			Review: review
		};
	})();
		
	return{
		Init: initOfferPanel
	};
})();

(function(){
	sadNegotiator.Init();
})();